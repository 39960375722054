import { FC, MouseEventHandler } from "react";
import { DotsHorizontalIcon } from "shared/ui/icons";
import { TrashIcon, PencilIcon } from "@heroicons/react/24/outline";

interface SalesFloorCommentActionsPropsI {
  isDeleteAvailable: boolean;
  isEditAvailable: boolean;
  onDelete: () => Promise<boolean>;
  onEdit: () => void;
}

export const SalesFloorCommentActions: FC<SalesFloorCommentActionsPropsI> = ({
  isDeleteAvailable,
  onDelete,
  isEditAvailable,
  onEdit,
}) => {
  const handleEdit: MouseEventHandler<HTMLLIElement> = (e) => {
    e.preventDefault();

    if (isEditAvailable) {
      onEdit();
    }
  };

  const handleDelete: MouseEventHandler<HTMLLIElement> = (e) => {
    e.preventDefault();

    if (isDeleteAvailable) {
      onDelete();
    }
  };

  if (!isDeleteAvailable && !isEditAvailable) {
    return null;
  }

  return (
    <div className="dropdown-end dropdown" onClick={(e) => e.stopPropagation()}>
      <label tabIndex={1} className="btn-ghost btn-sm btn-circle btn p-0">
        <DotsHorizontalIcon className="w-4" />
      </label>

      <ul
        tabIndex={1}
        className="dropdown-content menu bg-base-100 w-[180px] rounded p-2 shadow-2xl"
      >
        {isEditAvailable && (
          <li onClick={handleEdit}>
            <span className="flex gap-1 rounded py-1.5 active:bg-black/20 active:text-black">
              <PencilIcon className="h-5 w-5" />
              <span className="b-typography-body3">Edit Reply</span>
            </span>
          </li>
        )}

        {isDeleteAvailable && (
          <li onClick={handleDelete}>
            <span className="flex gap-1 rounded py-1.5 active:bg-black/20 active:text-black">
              <TrashIcon className="h-5 w-5" />
              <span className="b-typography-body3">Delete Reply</span>
            </span>
          </li>
        )}
      </ul>
    </div>
  );
};
