import { FC, ReactElement, useMemo } from "react";
import clsx from "clsx";

import { SalesFloorPostAuthor as Author } from "./shared/post-author";
import { SalesFloorPostCommentsLabel as Comments } from "./shared/post-comments";
import { SalesFloorPostContent as Content } from "./shared/post-content";
import { SalesFloorPostLabels as Labels } from "./shared/post-labels";
import { SalesFloorPostActions as Actions } from "./shared/post-actions";
import { SalesFloorPostNotifications as Notifications } from "./shared/post-notifications";
import { SalesFloorPostRating as Rating } from "./shared/post-rating";
import { SalesFloorPostComment as Comment } from "./post-comment";
import { SalesFloorPostAttachments as Attachments } from "./shared/post-attachments";
import { SalesFloorPostPropsI } from "./interface";

import { CenteredSpinner } from "shared/ui/spinners";
import { ForumPostCommentI } from "shared/lib/interfaces/forum";

export interface SalesFloorPostExpandedPropsI extends SalesFloorPostPropsI {
  isLoading?: boolean;
  hasMoreComments?: boolean;
  onLoadMoreComments?: () => void;
  comments?: Array<ForumPostCommentI>;
  onReply?: () => void;
  onEdit?: () => void;
  postEditor?: ReactElement | null;
  onUpvoteComment?: (postId: string, commentId: string) => Promise<boolean>;
  onEditComment: (commentId: string) => void;
  commentToEditId: string | null;
  onDeleteComment?: (postId: string, commentId: string) => Promise<boolean>;
  commentEditor?: ReactElement | null;
}

// TODO work in progress
export const SalesFloorPostExpanded: FC<SalesFloorPostExpandedPropsI> = ({
  currentUserId,
  isCustomerForum = false,
  isReadOnly = false,
  post,
  isLoading,
  hasMoreComments,
  onLoadMoreComments,
  comments,
  onClick,
  postEditor,
  onEdit,
  onUpvote,
  onRemove,
  onPin,
  onUnpin,

  onUpvoteComment,
  onDeleteComment,
  onEditComment,
  commentToEditId,
  commentEditor,
}) => {
  const isMyPost = useMemo(
    () => currentUserId === post.poster_id,
    [currentUserId, post.poster_id]
  );

  const handleUpvoteComment = (commentId?: string) => {
    if (onUpvoteComment && commentId) {
      return onUpvoteComment(post.id, commentId);
    }

    return Promise.resolve(false);
  };

  const handleDeleteComment = (commentId?: string) => {
    if (onDeleteComment && commentId) {
      return onDeleteComment(post.id, commentId);
    }

    return Promise.resolve(false);
  };

  return (
    <>
      <div
        className={clsx("group w-full rounded-xl bg-white p-6", {
          "cursor-pointer": !!onClick,
        })}
        onClick={onClick}
      >
        <div className="flex">
          <div>
            <Rating
              isReadOnly={isReadOnly}
              rating={post.upvotes}
              isUpvoted={post.upvoted}
              onUpvote={onUpvote}
            />
          </div>

          <div className="group ml-5 w-full">
            <div className="flex items-center justify-between">
              <Author
                name={post.name}
                profileImageURL={post.profile_pic_link}
                profileURL={post.is_customer ? null : post.name_link}
                createdAt={post.created_at}
                isCustomer={post.is_customer}
              />

              <div className="flex items-center gap-2 pr-12">
                <Labels isPinned={post.is_pinned} />

                <Actions
                  isEditAvailable={isMyPost}
                  isRemoveAvailable={isCustomerForum || isMyPost}
                  isPinAvailable={isCustomerForum}
                  isPinned={post.is_pinned}
                  onEdit={onEdit}
                  onRemove={onRemove}
                  onPin={onPin}
                  onUnpin={onUnpin}
                />
              </div>
            </div>

            <div className="mt-2">
              {postEditor || (
                <Content
                  isPreview={false}
                  title={post.title}
                  body={post.body}
                />
              )}
            </div>

            <Attachments
              audios={post.attachments?.recordings}
              className="my-8"
            />

            <div className="group mt-3 flex items-center">
              <Comments commentsCount={post.comment_count} />

              <Notifications />
            </div>
          </div>
        </div>

        <div className="my-6 h-[1px] w-full bg-[#EEEEEE]"></div>

        <div className="flex flex-col">
          {isLoading && (!comments || comments.length === 0) && (
            <CenteredSpinner className="animate-fadein sticky opacity-40" />
          )}

          <div className="relative w-full pb-4">
            {!comments?.length && !isLoading && (
              <div className="my-6 flex items-center justify-center">
                <span className="ae-typography-h3 text-black/30">
                  No Comments
                </span>
              </div>
            )}

            {comments?.map((c) => (
              <Comment
                key={c?.id}
                className="animate-fadein"
                isMyComment={currentUserId === c?.poster_id || isCustomerForum}
                isEditMode={commentToEditId === c.id}
                commentEditor={commentEditor}
                comment={c}
                onUpvote={() => handleUpvoteComment(c.id)}
                onEdit={() => onEditComment(c.id)}
                onDelete={() => handleDeleteComment(c.id)}
              />
            ))}

            {hasMoreComments && (
              <div className="flex justify-center">
                <button
                  className={clsx("btn-ae-default min-w-[220px]", {
                    "loading disabled": isLoading,
                  })}
                  onClick={onLoadMoreComments}
                >
                  See more
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="sticky bottom-[24px] w-full">
          {!!commentEditor && !commentToEditId && commentEditor}
        </div>
      </div>
    </>
  );
};
