import { FC, useMemo } from "react";
import clsx from "clsx";

import Avatar from "shared/ui/avatar";
import CustomerCrownLabel from "shared/ui/customer-crown-label";
import { getInitialsFromUsername, getS3AssetPath } from "shared/lib/helpers";
import { useTimeAgo } from "shared/lib/hooks/use-time-ago";

const DATE_POSITIONS = {
  BOTTOM: "bottom",
  RIGHT: "right",
} as const;

interface SalesFloorPostAuthorPropsI {
  profileImageURL?: string;
  name: string;
  profileURL: string | null;
  createdAt: string;
  isCustomer: boolean;
  datePosition?: (typeof DATE_POSITIONS)[keyof typeof DATE_POSITIONS];
}

export const SalesFloorPostAuthor: FC<SalesFloorPostAuthorPropsI> = ({
  name,
  profileImageURL,
  profileURL,
  createdAt,
  isCustomer,
  datePosition = DATE_POSITIONS.BOTTOM,
}) => {
  const postCreatedTimeAgo = useTimeAgo(createdAt);

  const postAuthorInternals = useMemo(
    () => (
      <div className="flex items-center gap-3">
        <div className="relative">
          {isCustomer && (
            <img
              className="absolute -left-[4px] -top-[9px] h-[42px] w-[38px] max-w-none"
              alt="Author avatar crown"
              src={getS3AssetPath("platform/forums/crown.webp")}
            />
          )}

          <Avatar
            placeholderText={getInitialsFromUsername(name)}
            className={clsx("h-8 w-8")}
          />
        </div>

        <div
          className={clsx("flex", {
            "flex-col": datePosition === DATE_POSITIONS.BOTTOM,
          })}
        >
          <span className="ae-typography-body1 flex items-center">
            <span> {name}</span>

            {isCustomer && <CustomerCrownLabel className="ml-3" />}
          </span>

          <span
            className={clsx("ae-typography-body2 text-black/60", {
              "relative top-[2px] ml-3": datePosition === DATE_POSITIONS.RIGHT,
            })}
          >
            {postCreatedTimeAgo}
          </span>
        </div>
      </div>
    ),
    [name, profileImageURL, postCreatedTimeAgo]
  );

  return profileURL ? (
    <a
      onClick={(e) => e.stopPropagation()}
      href={profileURL}
      target="_blank"
      rel="noreferrer"
    >
      {postAuthorInternals}
    </a>
  ) : (
    postAuthorInternals
  );
};
