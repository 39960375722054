import { useGlobalContext } from "@/hooks/use-global-context";
import SalesFloorPostEditor from "shared/ui/sales-floor/post-editor";
import { MouseEventHandler, useMemo } from "react";
import clsx from "clsx";

export const SalesFloorCommentEditor = ({
  entityId,
  onSubmit,
  isEditMode = false,
  onExitEditMode,
  body,
}: {
  entityId: string; // post id or comment id
  onSubmit: (title?: string, body?: string) => Promise<boolean>;
  isEditMode?: boolean;
  onExitEditMode?: () => void;
  body?: string;
}) => {
  const { glencocoUser: user } = useGlobalContext();

  const handleCancelButtonClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();

    if (onExitEditMode) {
      onExitEditMode();
    }
  };

  const editorProps = useMemo(
    () =>
      isEditMode
        ? {
            localStorageId: `user_${user?.id}_${entityId}_edited_comment`,
            body,
            submitButtonText: "Save",
            submitButtonLoadingText: "Saving...",
          }
        : {
            localStorageId: `user_${user?.id}_${entityId}_persisted_comment`,
            submitButtonText: "Comment",
            submitButtonLoadingText: "Commenting...",
          },
    [isEditMode, entityId, onExitEditMode]
  );

  return (
    <SalesFloorPostEditor
      hideTitle
      isAuthorVisible={!isEditMode}
      userName={`${user?.first_name} ${user?.last_name}`}
      onSubmit={onSubmit}
      className={clsx("mt-4 rounded-[12px] border border-[#EEEEEE]", {
        "pt-2": isEditMode,
      })}
      editorContentClassName="mt-3 mb-2"
      onCancel={handleCancelButtonClick}
      {...editorProps}
    />
  );
};
