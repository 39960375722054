import {
  FC,
  FormEventHandler,
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import clsx from "clsx";
import { twMerge } from "tailwind-merge";
import Placeholder from "@tiptap/extension-placeholder";
import Link from "@tiptap/extension-link";
import History from "@tiptap/extension-history";
import CharacterCount from "@tiptap/extension-character-count";
import { EditorContent, useEditor } from "@tiptap/react";
import { EditorOptions } from "@tiptap/core";
import _throttle from "lodash/throttle";
import * as Yup from "yup";
import { Portal } from "react-portal";

import { LinkIcon } from "shared/ui/icons";
import Avatar from "shared/ui/avatar";
import { Tooltip, TooltipContent, TooltipTrigger } from "shared/ui/tooltip";
import LinkManagerModal from "./link-manager-modal";
import { modalHelpers } from "shared/lib/helpers/modalHelpers";
import { HTTPS_REGEX } from "shared/lib/constants/urls";
import { COMMON_TIPTAP_EXTENSIONS } from "shared/ui/sales-floor/constants/tiptap";
import {
  checkIfClient,
  getInitialsFromUsername,
  getS3AssetPath,
} from "shared/lib/helpers";

export interface SalesFloorPostEditorPropsI {
  localStorageId?: string;

  className?: string;
  editorContentClassName?: string;
  placeholder?: string;

  title?: string;
  hideTitle?: boolean;

  body?: string;

  isCustomer?: boolean;

  userAvatarURL?: string;
  userName?: string;
  isAuthorVisible?: boolean;

  submitButtonText?: string;
  submitButtonLoadingText?: string;
  isSubmitButtonDisabled?: boolean;

  extensions?: {
    additionalActionsArea?: any;
  };

  onCancel?: MouseEventHandler<HTMLButtonElement>;
  onSubmit: (title: string, body: string) => Promise<boolean>;
}

const POST_TITLE_MAX_LENGTH = 100;
const POST_BODY_MAX_LENGTH = 3000;

const DEFAULT_PLACEHOLDER = "Start typing...";

const getPersistedEditorContent = (localStorageId: string) => {
  const defaultContent = null;

  if (checkIfClient()) {
    const persistedContent = localStorage.getItem(localStorageId);

    return persistedContent ? JSON.parse(persistedContent) : defaultContent;
  }

  return defaultContent;
};

const persistEditorContent = ({
  localStorageId,
  title,
  body,
  hideTitle,
}: {
  localStorageId: string;
  title: string;
  body: string;
  hideTitle: boolean;
}) => {
  if (!checkIfClient()) {
    return;
  }

  const persistedContent = getPersistedEditorContent(localStorageId);

  const isContentEmpty = hideTitle ? !body : !title && !body;

  if (persistedContent && isContentEmpty) {
    return localStorage.removeItem(localStorageId);
  }

  localStorage.setItem(localStorageId, JSON.stringify({ title, body }));
};

const SalesFloorPostEditor: FC<SalesFloorPostEditorPropsI> = ({
  localStorageId,
  placeholder = DEFAULT_PLACEHOLDER,
  isCustomer = false,
  isAuthorVisible = true,
  title: preEnteredTitle,
  body,
  userAvatarURL,
  userName,
  className,
  editorContentClassName,
  submitButtonText = "Post",
  submitButtonLoadingText = "Posting...",
  isSubmitButtonDisabled,

  hideTitle = false,

  extensions,

  onCancel,
  onSubmit,
}) => {
  const persistedContentRef = useRef(
    localStorageId ? getPersistedEditorContent(localStorageId) : null
  );

  const [linkToEdit, setLinkToEdit] = useState<string | undefined>();
  const [isLinkManagerVisible, setIsLinkModalManagerVisible] = useState(false);
  const [title, setTitle] = useState<string>(
    persistedContentRef.current?.title || preEnteredTitle || ""
  );
  const [plainBody, setPlainBody] = useState<string>(
    persistedContentRef.current?.body || ""
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleCloseLinkManagerModal = () => {
    setIsLinkModalManagerVisible(false);
    setLinkToEdit(undefined);
  };

  const handleOpenLinkManagerModal = () => {
    if (localStorageId) {
      modalHelpers.trigger(localStorageId);
    }
  };

  const editLinkHandler = useMemo(
    () =>
      _throttle<EditorOptions["onSelectionUpdate"]>(({ editor }) => {
        if (editor.isActive("link") && !isLinkManagerVisible) {
          setIsLinkModalManagerVisible(true);
          setLinkToEdit(editor.getAttributes("link").href);
          handleOpenLinkManagerModal();
        }
      }, 500),
    [isLinkManagerVisible]
  );

  const editorExtensions = useMemo(
    () => [
      ...COMMON_TIPTAP_EXTENSIONS,
      Link.extend({ inclusive: false }).configure({
        protocols: ["https"],
        openOnClick: false,
        validate: (url) => HTTPS_REGEX.test(url),
        HTMLAttributes: {
          rel: null,
          target: null,
        },
      }),
      Placeholder.configure({
        placeholder,
      }),
      History.configure({
        depth: 10,
      }),
      CharacterCount.configure({
        mode: "textSize",
        limit: POST_BODY_MAX_LENGTH,
      }),
    ],
    [placeholder]
  );

  const editor = useEditor({
    autofocus: hideTitle,
    content:
      body ||
      (persistedContentRef.current ? persistedContentRef.current.body : ""),
    editorProps: {
      attributes: {
        class: "focus:outline-none b-typography-body3",
      },
    },
    extensions: editorExtensions,
    onUpdate: ({ editor }) => {
      setPlainBody(editor.getText());
    },
    onSelectionUpdate: editLinkHandler,
  });

  // Emit editor update if pre-entered body is provided to keep plainBody and validation state in-sync
  useEffect(() => {
    if (editor && body) {
      editor.commands.setContent(body, true);
    }
  }, [body, editor]);

  const handleInsertLink = useCallback(
    (href: string) => {
      if (!editor) {
        return;
      }

      if (linkToEdit) {
        editor
          .chain()
          .extendMarkRange("link")
          .setLink({ href })
          .command(({ tr }) => {
            tr.insertText(href);

            return true;
          })
          .run();
      } else {
        editor.chain().insertContent(href).setLink({ href }).focus().run();
      }

      editor.chain().insertContent(" ").focus().run();
    },
    [editor, linkToEdit]
  );

  const handleClearPostContent = () => {
    setTitle("");

    if (editor) {
      editor.commands.setContent("", true);
    }

    if (localStorageId) {
      persistEditorContent({ localStorageId, title: "", body: "", hideTitle });
    }
  };

  const isFormValid = useMemo(() => {
    /**
     * NOTE
     * plainBody.length is not equal editor?.storage.characterCount.characters()
     * on massive chunks of rich text
     */

    const postFormSchema = hideTitle
      ? Yup.object().shape({
          bodyCount: Yup.number().max(POST_BODY_MAX_LENGTH).required(),
        })
      : Yup.object().shape({
          title: Yup.string().max(POST_TITLE_MAX_LENGTH).required(),
          bodyCount: Yup.number().max(POST_BODY_MAX_LENGTH).required(),
        });

    return postFormSchema.isValidSync({
      title,
      bodyCount: editor?.storage.characterCount.characters() || 0,
    });
  }, [title, plainBody, editor, hideTitle]);

  const handleSubmit: FormEventHandler<HTMLButtonElement> = async (e) => {
    e.preventDefault();

    if (isFormValid && editor) {
      try {
        setIsSubmitting(true);

        const result = await onSubmit(title, editor.getHTML());

        if (result) {
          handleClearPostContent();
        }
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  useEffect(() => {
    if (editor && localStorageId) {
      persistEditorContent({
        localStorageId,
        title,
        body: plainBody ? editor.getHTML() : "",
        hideTitle,
      });
    }
  }, [localStorageId, title, plainBody, editor, hideTitle]);

  return (
    <>
      <div
        className={clsx(
          twMerge("flex flex-col gap-2 rounded-xl bg-white p-5", className)
        )}
      >
        {isAuthorVisible && userName && (
          <div className="relative flex items-center gap-4">
            {isCustomer && (
              <img
                className="absolute -left-[4px] -top-[9px] h-[42px] w-[38px] max-w-none"
                src={getS3AssetPath("platform/forums/crown.webp")}
                alt="crown"
              />
            )}

            <Avatar
              className="h-8 w-8"
              src={userAvatarURL}
              placeholderText={getInitialsFromUsername(userName)}
            />
            <span className="ae-typography-h3">{userName}</span>
          </div>
        )}

        <div className="flex flex-col">
          {!hideTitle && (
            <input
              autoFocus={!hideTitle}
              className="ae-typography-h2 mt-4 outline-0 placeholder:text-[#999]"
              placeholder="Title"
              maxLength={POST_TITLE_MAX_LENGTH}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          )}

          <EditorContent
            className={twMerge(clsx("mb-5 mt-3", editorContentClassName))}
            editor={editor}
          />

          {/* Additional Actions Area */}
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-4">
              <Tooltip placement="right">
                <TooltipContent>
                  <span className="text-white">Insert a link</span>
                </TooltipContent>
                <TooltipTrigger
                  className="btn btn-ae-text p-0"
                  onClick={handleOpenLinkManagerModal}
                >
                  <LinkIcon className="h-4 w-4 text-black" />
                </TooltipTrigger>
              </Tooltip>

              {!editor?.isEmpty && (
                <span className="ae-typography-body4 text-black/60">
                  {editor?.storage.characterCount.characters()}/
                  {POST_BODY_MAX_LENGTH} characters
                </span>
              )}

              {!!extensions?.additionalActionsArea &&
                extensions?.additionalActionsArea}
            </div>

            {/* Buttons Area */}
            <div className="flex gap-3">
              {(!editor?.isEmpty || title) && !onCancel && (
                <button
                  className="btn btn-ae-text"
                  onClick={handleClearPostContent}
                >
                  Clear
                </button>
              )}

              {onCancel && (
                <button className="btn btn-ae-text" onClick={onCancel}>
                  Cancel
                </button>
              )}

              <button
                className={clsx("btn btn-ae-default min-w-[125px]", {
                  disabled:
                    isSubmitButtonDisabled || !isFormValid || isSubmitting,
                  loading: isSubmitting,
                })}
                onClick={handleSubmit}
              >
                {isSubmitting && submitButtonLoadingText
                  ? submitButtonLoadingText
                  : submitButtonText}
              </button>
            </div>
          </div>
        </div>
      </div>

      <Portal>
        <LinkManagerModal
          modalId={localStorageId}
          link={linkToEdit}
          onClose={handleCloseLinkManagerModal}
          onSubmit={handleInsertLink}
        />
      </Portal>
    </>
  );
};

export default SalesFloorPostEditor;
