import { FC, MouseEventHandler, useState } from "react";
import clsx from "clsx";
import { ArrowUpIcon } from "@heroicons/react/24/solid";
import { twMerge } from "tailwind-merge";

import { formatNumbers } from "shared/lib/helpers/numbers";

interface SalesFloorPostRatingPropsI {
  isReadOnly?: boolean;
  rating: number;
  isUpvoted: boolean;
  className?: string;
  onUpvote: () => Promise<boolean>;
}

export const SalesFloorPostRating: FC<SalesFloorPostRatingPropsI> = ({
  isReadOnly = false,
  rating,
  isUpvoted,
  className,
  onUpvote,
}) => {
  const [isUpvoting, setIsUpvoting] = useState(false);

  const handleUpvote: MouseEventHandler<HTMLDivElement> = async (e) => {
    if (isReadOnly) {
      return;
    }

    e.stopPropagation();

    if (isUpvoted) {
      return;
    }

    setIsUpvoting(true);
    await onUpvote();
    setIsUpvoting(false);
  };

  return (
    <div
      className={twMerge(
        clsx(
          "group/rating",
          "flex flex-col items-center justify-center",
          "min-w-10 gap-1.5 p-3",
          "rounded-xl border bg-[#F5F5F5]",
          isUpvoted ? "border-[#014DFE] text-[#014DFE]" : "text-[#999]",
          {
            "cursor-pointer": !isUpvoted && !isUpvoting && !isReadOnly,
          },
          className
        )
      )}
      onClick={handleUpvote}
    >
      <ArrowUpIcon
        className={clsx("w-5", {
          "transition-transform group-hover/rating:-translate-y-0.5":
            !isUpvoted,
        })}
      />

      <span className="b-typography-button1">
        {formatNumbers(rating, 3, { notation: "compact" })}
      </span>
    </div>
  );
};
