import { FC } from "react";
import { PinIcon } from "shared/ui/icons";

interface SalesFloorPostLabelsPropsI {
  isPinned: boolean;
}

const PinnedLabel = () => (
  <div className="flex h-min items-center gap-2 rounded-lg bg-[#014DFE] px-2 py-1 text-white">
    <PinIcon className="w-[10px]" />
    <span className="ae-typography-body1">Pinned</span>
  </div>
);

export const SalesFloorPostLabels: FC<SalesFloorPostLabelsPropsI> = ({
  isPinned,
}) => <div className="flex gap-1">{isPinned && <PinnedLabel />}</div>;
