import { FC } from "react";
import { DotsHorizontalIcon, PinIcon } from "shared/ui/icons";
import { TrashIcon, PencilIcon } from "@heroicons/react/24/outline";

interface SalesFloorPostActionsPropsI {
  isEditAvailable: boolean;
  isRemoveAvailable: boolean;
  isPinAvailable: boolean;
  isPinned: boolean;
  onEdit?: () => void;
  onRemove: () => Promise<boolean>;
  onPin?: () => Promise<boolean>;
  onUnpin?: () => Promise<boolean>;
}

export const SalesFloorPostActions: FC<SalesFloorPostActionsPropsI> = ({
  isEditAvailable,
  isPinAvailable,
  isRemoveAvailable,
  isPinned,
  onPin,
  onUnpin,
  onEdit,
  onRemove,
}) => {
  const handleRemove = () => {
    if (isRemoveAvailable) {
      onRemove();
    }
  };

  const togglePin = () => {
    if (!isPinAvailable) {
      return;
    }

    if (!isPinned && onPin) {
      onPin();
    } else if (isPinned && onUnpin) {
      onUnpin();
    }
  };

  if (!isRemoveAvailable && !isPinAvailable && !isEditAvailable) {
    return null;
  }

  return (
    <div className="dropdown-end dropdown" onClick={(e) => e.stopPropagation()}>
      <label tabIndex={1} className="btn-ghost btn-sm btn-circle btn p-0">
        <DotsHorizontalIcon className="w-4" />
      </label>

      <ul
        tabIndex={1}
        className="dropdown-content menu bg-base-100 w-[180px] rounded p-2 shadow-2xl"
      >
        {isPinAvailable && (
          <li onClick={togglePin}>
            <span className="flex gap-1 rounded py-1.5 active:bg-black/20 active:text-black">
              <PinIcon className="h-4 w-4" />
              <span className="b-typography-body3 ml-1">
                {isPinned ? "Unpin post" : "Pin post"}
              </span>
            </span>
          </li>
        )}

        {isEditAvailable && (
          <li onClick={onEdit}>
            <span className="flex gap-1 rounded py-1.5 active:bg-black/20 active:text-black">
              <PencilIcon className="h-5 w-5" />
              <span className="b-typography-body3">Edit post</span>
            </span>
          </li>
        )}

        {isRemoveAvailable && (
          <li onClick={handleRemove}>
            <span className="flex gap-1 rounded py-1.5 active:bg-black/20 active:text-black">
              <TrashIcon className="h-5 w-5" />
              <span className="b-typography-body3">Delete post</span>
            </span>
          </li>
        )}
      </ul>
    </div>
  );
};
