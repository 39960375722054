import { FC, useMemo } from "react";

import { SalesFloorPostActions as Actions } from "./shared/post-actions";
import { SalesFloorPostAuthor as Author } from "./shared/post-author";
import { SalesFloorPostCommentsLabel as CommentsLabel } from "./shared/post-comments";
import { SalesFloorPostContent as Content } from "./shared/post-content";
import { SalesFloorPostLabels as Labels } from "./shared/post-labels";
import { SalesFloorPostNotifications as Notifications } from "./shared/post-notifications";
import { SalesFloorPostRating as Rating } from "./shared/post-rating";
import { SalesFloorPostAttachments as Attachments } from "./shared/post-attachments";

import { SalesFloorPostPropsI } from "./interface";

import clsx from "clsx";
import { twMerge } from "tailwind-merge";

export const SalesFloorPost: FC<SalesFloorPostPropsI> = ({
  className,
  isCustomerForum = false,
  isReadOnly = false,
  isPostPreview = false,
  currentUserId,
  post,
  onClick,
  onUpvote,
  onEdit,
  onRemove,
  onPin,
  onUnpin,
}) => {
  const isMyPost = useMemo(
    () => currentUserId === post.poster_id,
    [currentUserId, post.poster_id]
  );

  return (
    <div
      className={twMerge(
        clsx(
          "flex w-full rounded-xl p-6",
          post.is_pinned ? "bg-[#E4ECFF]" : "bg-white",
          {
            "cursor-pointer": !!onClick,
          },
          className
        )
      )}
      onClick={onClick}
    >
      <div>
        <Rating
          isReadOnly={isReadOnly}
          rating={post.upvotes}
          isUpvoted={post.upvoted}
          onUpvote={onUpvote}
        />
      </div>

      <div className="ml-5 w-full">
        <div className="flex items-center justify-between">
          <Author
            name={post.name}
            profileImageURL={post.profile_pic_link}
            profileURL={post.is_customer ? null : post.name_link}
            createdAt={post.created_at}
            isCustomer={post.is_customer}
          />

          <div className="flex items-center gap-2">
            <Labels isPinned={post.is_pinned} />

            <Actions
              isEditAvailable={isMyPost}
              isRemoveAvailable={isMyPost || isCustomerForum}
              isPinAvailable={isCustomerForum}
              isPinned={post.is_pinned}
              onEdit={onEdit}
              onRemove={onRemove}
              onPin={onPin}
              onUnpin={onUnpin}
            />
          </div>
        </div>

        <div className="mt-2">
          <Content
            isPreview={isPostPreview}
            title={post.title}
            body={post.body}
          />
        </div>

        <Attachments audios={post.attachments?.recordings} className="my-8" />

        <div className="group mt-3 flex items-center">
          <CommentsLabel commentsCount={post.comment_count} />
          <Notifications />
        </div>
      </div>
    </div>
  );
};
