import { FC } from "react";
import clsx from "clsx";

import AudioPlayer from "shared/ui/audio-player";

interface SalesFloorPostAttachmentsPropsI {
  audios?: Array<string>;
  className?: string;
}

export const SalesFloorPostAttachments: FC<SalesFloorPostAttachmentsPropsI> = ({
  audios,
  className,
}) => {
  return audios?.length ? (
    <div className={clsx(className)}>
      <h5 className="b-typography-body1 mb-2 font-bold">Recordings:</h5>
      {audios.map((audioSrc, i) => (
        <AudioPlayer url={audioSrc} key={i} className="mb-2" />
      ))}
    </div>
  ) : null;
};
