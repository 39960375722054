import Document from "@tiptap/extension-document";
import Text from "@tiptap/extension-text";
import Paragraph from "@tiptap/extension-paragraph";
import Bold from "@tiptap/extension-bold";
import HardBreak from "@tiptap/extension-hard-break";

export const COMMON_TIPTAP_EXTENSIONS = [
  Document,
  Text,
  Paragraph,
  Bold,
  HardBreak,
];
