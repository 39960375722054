import { FC, useMemo, useState } from "react";

import { ForumPostI } from "shared/lib/interfaces/forum";

import { SalesFloorPostExpanded as PostExpanded } from "shared/ui/sales-floor/post/post-expanded";
import { SalesFloorCommentEditor as CommentEditor } from "./edit/comment-editor";
import { SalesFloorExpandedPostEditor as PostEditor } from "./edit/post-editor";

import { useForumContext } from "shared/ui/sales-floor/contexts/forum-context";
import { useEffectOnce } from "shared/lib/hooks/use-effect-once";

interface SalesFloorPostExpandedI {
  post: ForumPostI;
  currentUserId?: string;
  isInitiallyInEditMode?: boolean;
  isReadOnly?: boolean;
}

export const SalesFloorPostExpanded: FC<SalesFloorPostExpandedI> = ({
  post,
  currentUserId,
  isInitiallyInEditMode = false,
  isReadOnly = false,
}) => {
  const [, setIsCommentEditorVisible] = useState(false);
  const [isPostEditMode, setIsPostEditMode] = useState(isInitiallyInEditMode);
  const [commentToEditId, setCommentToEditId] = useState<string | null>(null);
  const isCommentEditMode = commentToEditId !== null;

  const {
    editPost,
    upvotePost,
    deletePost,

    isLoadingComments,
    comments,
    loadComments,
    createComment,
    isReachedEndOfComments,
    upvoteComment,
    editComment,
    deleteComment,
  } = useForumContext();

  const onUpvote = () => upvotePost(post.id);
  const onRemove = () => deletePost(post.id);
  const onReply = () => {
    setIsCommentEditorVisible(true);
  };

  const enterPostEditMode = () => {
    setIsPostEditMode(true);
  };

  const exitPostEditMode = () => {
    setIsPostEditMode(false);
  };

  const enterCommentEditMode = (commentId: string) => {
    setCommentToEditId(commentId);
  };

  const exitCommentEditMode = () => {
    if (isCommentEditMode) {
      setCommentToEditId(null);
    } else {
      setIsCommentEditorVisible(false);
    }
  };

  const postHasMoreComments = useMemo(
    () =>
      Boolean(
        !isReachedEndOfComments && comments?.length && comments.length > 0
      ),
    [isReachedEndOfComments, comments]
  );

  const onEditPost = async (title: string, body: string) => {
    await editPost(post.id, title, body);
    exitPostEditMode();

    return true;
  };

  const onCommentSubmit = async (_title?: string, body?: string) => {
    if (isCommentEditMode) {
      await editComment(post.id, commentToEditId, body as string);
      exitCommentEditMode();

      return true;
    }

    await createComment(post.id, body as string);
    setIsCommentEditorVisible(false);

    return true;
  };

  const handleLoadComments = () => {
    loadComments(post.id);
  };

  useEffectOnce(() => {
    handleLoadComments();
  });

  return (
    <>
      <PostExpanded
        isReadOnly={isReadOnly}
        post={post}
        currentUserId={currentUserId}
        isLoading={isLoadingComments}
        onUpvote={onUpvote}
        onRemove={onRemove}
        onReply={onReply}
        postEditor={
          isPostEditMode ? (
            <PostEditor
              post={post}
              onExitEditMode={exitPostEditMode}
              onSubmit={onEditPost}
            />
          ) : null
        }
        onEdit={enterPostEditMode}
        hasMoreComments={postHasMoreComments}
        onLoadMoreComments={handleLoadComments}
        comments={comments}
        onUpvoteComment={upvoteComment}
        onEditComment={enterCommentEditMode}
        commentToEditId={commentToEditId}
        commentEditor={
          <CommentEditor
            isEditMode={isCommentEditMode}
            onExitEditMode={exitCommentEditMode}
            onSubmit={onCommentSubmit}
            body={comments.find((c) => c.id === commentToEditId)?.body}
            entityId={commentToEditId || "new_comment"}
          />
        }
        onDeleteComment={deleteComment}
      />
    </>
  );
};
