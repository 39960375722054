import { FC, useMemo, useState } from "react";
import { Portal } from "react-portal";

import { SalesFloorPost as SharedSalesFloorPost } from "shared/ui/sales-floor/post";

import { ForumPostI } from "shared/lib/interfaces/forum";

import {
  ForumPostExpandedModal,
  FORM_POST_EXPANDED_MODAL_ID,
} from "@/components/modals/forum-post-expanded-modal";
import { modal } from "@/components/modals";
import { useForumContext } from "shared/ui/sales-floor/contexts/forum-context";

interface SalesFloorPostPropsI {
  post: ForumPostI;
  currentUserId?: string;
  className?: string;
  isReadOnly?: boolean;
  onOpenPost?: (postId: string) => void;
  onClosePost?: () => void;
}

export const SalesFloorPost: FC<SalesFloorPostPropsI> = ({
  post,
  currentUserId,
  className,
  isReadOnly = false, // Leaving this prop in case we need to make some posts read-only in future
  onOpenPost,
  onClosePost,
}) => {
  const [isExpandedPostEditMode, setIsExpandedPostEditMode] = useState(false);

  const modalIdForumExpandedPost = useMemo(
    () => `${FORM_POST_EXPANDED_MODAL_ID}-${post?.id}`,
    [post?.id]
  );

  const { upvotePost, deletePost, resetCommentsList } = useForumContext();

  const onUpvote = () => upvotePost(post.id);

  const onRemove = () => deletePost(post.id);

  const onOpenExpandedPost = () => {
    if (onOpenPost) {
      onOpenPost(post.id);
    }

    modal.trigger(modalIdForumExpandedPost);
  };

  const onCloseExpandedPost = () => {
    if (onClosePost) {
      onClosePost();
    }

    setIsExpandedPostEditMode(false);
    resetCommentsList();
  };

  const onEdit = () => {
    setIsExpandedPostEditMode(true);
    onOpenExpandedPost();
  };

  return (
    <>
      <SharedSalesFloorPost
        isPostPreview
        isReadOnly={isReadOnly}
        className={className}
        post={post}
        currentUserId={currentUserId}
        onUpvote={onUpvote}
        onEdit={onEdit}
        onRemove={onRemove}
        onClick={onOpenExpandedPost}
      />

      {/*TODO: Render only if was last mouse over */}
      <Portal>
        <ForumPostExpandedModal
          modalId={modalIdForumExpandedPost}
          post={post}
          isReadOnly={isReadOnly}
          currentUserId={currentUserId}
          isInitiallyInEditMode={isExpandedPostEditMode}
          onClose={onCloseExpandedPost}
        />
      </Portal>
    </>
  );
};
