import {
  createContext,
  FC,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import _update from "lodash/update";
import _cloneDeep from "lodash/cloneDeep";
import _findIndex from "lodash/findIndex";
import _filter from "lodash/filter";

import {
  ForumPostI,
  ForumApiI,
  ForumPostSortOrderI,
  ForumPostCommentI,
  GetForumPostResponseI,
} from "shared/lib/interfaces/forum";
import {
  FORUM_POST_SORT_ORDERS,
  SHARED_FORUM_API_METHODS,
} from "shared/lib/constants/forum";
import { useApi } from "shared/lib/hooks/use-api";
import { checkIfClient } from "shared/lib/helpers";

interface ForumProviderPropsI {
  children?: ReactElement;
  campaignId: string;
  apiFactory: () => ForumApiI;
  isCustomerForum?: boolean;
  configLoadPostsRequest?: any;
}

interface CreatePostConfigI {
  isPostsRefreshOnSuccess?: boolean;
}

interface ForumContextI {
  posts: ForumPostI[];
  createPost: (
    title: string,
    body: string,
    post_type?: string,
    config?: CreatePostConfigI
  ) => Promise<any>;
  editPost: (postId: string, title: string, body: string) => Promise<any>;
  loadPosts: () => Promise<any>;
  configLoadPostsRequest?: any;
  setConfigLoadPostsRequest: (config?: any) => void;
  loadPost: (postId: string) => Promise<GetForumPostResponseI | null>;
  isLoadingPosts: boolean;
  isReachedEndOfPosts: boolean;
  upvotePost: (postId: string) => Promise<boolean>;
  pinPost: (postId: string) => Promise<boolean>;
  unpinPost: (postId: string) => Promise<boolean>;
  deletePost: (postId: string) => Promise<boolean>;
  setSortOrder: (sortOrder: ForumPostSortOrderI) => void;
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  resetPostsList: () => void;

  resetCommentsList: () => void;
  createComment: (postId: string, body: string) => Promise<any>;
  editComment: (
    postId: string,
    commentId: string,
    body: string
  ) => Promise<any>;
  loadComments: (postId: string) => Promise<any>;
  isLoadingComments: boolean;
  isReachedEndOfComments: boolean;
  upvoteComment: (postId: string, commentId: string) => Promise<boolean>;
  comments: ForumPostCommentI[];
  deleteComment: (postId: string, commentId: string) => Promise<boolean>;
}

const ForumContext = createContext<ForumContextI>({
  posts: [],
  createPost: () => Promise.resolve(true),
  editPost: () => Promise.resolve(true),
  loadPosts: () => Promise.resolve(true),
  configLoadPostsRequest: undefined,
  setConfigLoadPostsRequest: () => {},
  loadPost: () => Promise.resolve({} as GetForumPostResponseI),
  isLoadingPosts: false,
  isReachedEndOfPosts: false,
  upvotePost: () => Promise.resolve(true),
  pinPost: () => Promise.resolve(true),
  unpinPost: () => Promise.resolve(true),
  deletePost: () => Promise.resolve(true),
  searchTerm: "",
  setSearchTerm: () => {},
  setSortOrder: () => {},
  resetPostsList: () => {},

  resetCommentsList: () => {},
  createComment: () => Promise.resolve(true),
  loadComments: () => Promise.resolve(true),
  isLoadingComments: false,
  isReachedEndOfComments: false,
  upvoteComment: () => Promise.resolve(true),
  comments: [],
  editComment: () => Promise.resolve(true),
  deleteComment: () => Promise.resolve(true),
});

const FORUM_SORT_ORDER_STORAGE_KEY = "forum_sort_order";

const persistForumSortOrder = (sortOrder: ForumPostSortOrderI) => {
  if (!checkIfClient()) {
    return;
  }

  localStorage.setItem(FORUM_SORT_ORDER_STORAGE_KEY, sortOrder);
};

export const getPersistedForumSortOrder = (): ForumPostSortOrderI | null => {
  if (!checkIfClient()) {
    return null;
  }

  const persistedSortOrder = localStorage.getItem(FORUM_SORT_ORDER_STORAGE_KEY);

  return persistedSortOrder
    ? (persistedSortOrder as ForumPostSortOrderI)
    : null;
};

export const useForumContext = () => useContext(ForumContext);

const immutableUpdateArrayItem = <T,>(
  array: Array<T>,
  path: string,
  updater: (post: T) => T | undefined
): Array<T> => _cloneDeep(_update(array, path, updater));

export const ForumProvider: FC<ForumProviderPropsI> = ({
  children,
  campaignId,
  apiFactory,
  isCustomerForum = false,

  //Free form request configs unique to the project
  configLoadPostsRequest: _configLoadPostsRequest,
}) => {
  const postsNextTokenRef = useRef<string | null>(null);
  const [sortOrder, setSortOrder] = useState<ForumPostSortOrderI>(
    getPersistedForumSortOrder() || FORUM_POST_SORT_ORDERS.UPVOTES
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [configLoadPostsRequest, setConfigLoadPostsRequest] = useState<any>(
    _configLoadPostsRequest
  );
  const [isReachedEndOfPosts, setIsReachedEndOfPosts] = useState(false);
  const [internalPosts, setInternalPosts] = useState<ForumPostI[]>([]);

  const resetPostsList = () => {
    setInternalPosts([]);
    postsNextTokenRef.current = null;
    setIsReachedEndOfPosts(false);
  };

  const handleChangeSortOrder = (newSortOrder: ForumPostSortOrderI) => {
    if (newSortOrder !== sortOrder) {
      persistForumSortOrder(newSortOrder);
      resetPostsList();
    }

    setSortOrder(newSortOrder);
  };

  const handeChangeSearchTerm = (newSearchTerm: string) => {
    if (newSearchTerm !== searchTerm) {
      resetPostsList();
    }

    setSearchTerm(newSearchTerm);
  };

  const getPostsFetcher = useMemo(() => {
    return campaignId
      ? (api: ForumApiI) => {
          return api[SHARED_FORUM_API_METHODS.GET_POSTS](
            campaignId as string,
            {
              sort_order: sortOrder,
              search_term: searchTerm,
              next_token: postsNextTokenRef.current,
            },
            configLoadPostsRequest
          );
        }
      : null;
  }, [campaignId, sortOrder, searchTerm, configLoadPostsRequest]);

  useEffect(() => {
    setConfigLoadPostsRequest(_configLoadPostsRequest);
  }, [_configLoadPostsRequest]);

  const [{ isLoading: isLoadingPosts }, loadPosts] = useApi({
    apiFactory,
    fetcher: getPostsFetcher,
    shouldResetDataOnFailure: false,
    shouldResetDataOnRequest: false,
    errorBuilder: () =>
      "Something went wrong while loading posts. Please try again later.",
    onSuccess: ({ next_token, posts }) => {
      setInternalPosts((internalPosts) => [...internalPosts, ...posts]);

      if (next_token === null || !posts || posts?.length === 0) {
        setIsReachedEndOfPosts(true);
      }

      postsNextTokenRef.current = next_token;
    },
    onError: () => {
      setIsReachedEndOfPosts(true);
      postsNextTokenRef.current = null;
    },
  });

  // Immutable posts updater utility
  const updatePostById = useCallback(
    (postId: string, updater: (post: ForumPostI) => ForumPostI | undefined) => {
      const postToUpdateIndex = _findIndex(internalPosts, { id: postId });

      if (postToUpdateIndex >= 0) {
        setInternalPosts((currentInternalPosts) =>
          // Reject undefined values (after deletion)
          _filter(
            immutableUpdateArrayItem(
              currentInternalPosts,
              `[${postToUpdateIndex}]`,
              updater
            ),
            Boolean
          )
        );
      }
    },
    [internalPosts]
  );

  // Create post
  const createPostFetcher = useMemo(
    () =>
      campaignId
        ? (api: ForumApiI, title: string, body: string, post_type?: string) =>
            api[SHARED_FORUM_API_METHODS.CREATE_POST](campaignId as string, {
              title,
              body,
              post_type,
            })
        : null,
    [campaignId]
  );

  const [, createPost] = useApi({
    apiFactory,
    shouldCallAutomatically: false,
    fetcher: createPostFetcher,
    showToastOnSuccess: true,
    successBuilder: () => "Post has been created successfully!",
    errorBuilder: () =>
      "Something went wrong during post creation. Please try again later.",
  });

  const handleCreatePost = useCallback(
    (
      title: string,
      body: string,
      post_type?: string,
      config?: CreatePostConfigI
    ) =>
      createPost(title, body, post_type)
        .then(() => {
          const defaultConfig: CreatePostConfigI = {
            isPostsRefreshOnSuccess: true,
          };
          const { isPostsRefreshOnSuccess } = config
            ? { ...defaultConfig, ...config }
            : defaultConfig;

          if (isPostsRefreshOnSuccess) {
            resetPostsList();
            loadPosts();
          }

          return Promise.resolve(true);
        })
        .catch(() => Promise.reject()),
    [createPost, loadPosts, sortOrder, searchTerm]
  );

  const editPostFetcher = useMemo(
    () =>
      campaignId
        ? (api: ForumApiI, postId: string, title: string, body: string) =>
            api[SHARED_FORUM_API_METHODS.EDIT_POST](campaignId, postId, {
              title,
              body,
            })
        : null,
    [campaignId]
  );

  const [, editPost] = useApi({
    apiFactory,
    shouldCallAutomatically: false,
    fetcher: editPostFetcher,
    showToastOnSuccess: true,
    successBuilder: () => "Post has been edited successfully!",
    errorBuilder: () =>
      "Something went wrong during post edit. Please try again later.",
  });

  const handleEditPost = async (
    postId: string,
    title: string,
    body: string
  ) => {
    try {
      await editPost(postId, title, body);

      updatePostById(postId, (post) => ({
        ...post,
        title,
        body,
      }));

      return true;
    } catch (e) {
      return false;
    }
  };

  // Upvote post
  const upvoteFetcher = useMemo(
    () =>
      campaignId
        ? (api: ForumApiI, postId: string) =>
            api[SHARED_FORUM_API_METHODS.UPVOTE_POST](campaignId, postId)
        : null,
    [campaignId]
  );

  const [, upvotePost] = useApi({
    apiFactory,
    fetcher: upvoteFetcher,
    shouldCallAutomatically: false,
    errorBuilder: () => "Something went wrong during upvote. Please try again.",
  });

  const handleUpvotePost = async (postId: string) => {
    updatePostById(postId, (post) => ({
      ...post,
      upvotes: post.upvotes + 1,
      upvoted: true,
    }));

    try {
      await upvotePost(postId);

      return true;
    } catch (e) {
      updatePostById(postId, (post) => ({
        ...post,
        upvotes: post.upvotes - 1,
        upvoted: false,
      }));

      return false;
    }
  };

  const pinPostFetcher = useMemo(
    () =>
      campaignId
        ? (api: ForumApiI, postId: string) =>
            api[SHARED_FORUM_API_METHODS.PIN_POST]?.(campaignId, postId)
        : null,
    [campaignId]
  );

  const [, pinPost] = useApi({
    // @ts-ignore
    fetcher: pinPostFetcher,
    shouldCallAutomatically: false,
    apiFactory,
    errorBuilder: () =>
      "Something went wrong during post pinning. Please try again.",
    onSuccess: () => {
      resetPostsList();
      loadPosts();
    },
  });

  const handlePinPost = async (postId: string) => {
    if (!isCustomerForum) {
      return Promise.resolve(false);
    }

    try {
      await pinPost(postId);

      return false;
    } catch (e) {
      return false;
    }
  };

  const unpinPostFetcher = useMemo(
    () =>
      campaignId
        ? (api: ForumApiI, postId: string) =>
            api[SHARED_FORUM_API_METHODS.UNPIN_POST]?.(campaignId, postId)
        : null,
    [campaignId]
  );

  const [, unpinPost] = useApi({
    // @ts-ignore
    fetcher: unpinPostFetcher,
    shouldCallAutomatically: false,
    apiFactory,
    errorBuilder: () =>
      "Something went wrong during post unpinning. Please try again.",
    onSuccess: () => {
      resetPostsList();
      loadPosts();
    },
  });

  // Pin and unpin post (works only for customers)
  const handleUnpinPost = async (postId: string) => {
    if (!isCustomerForum) {
      return Promise.resolve(false);
    }

    try {
      await unpinPost(postId);

      return true;
    } catch (e) {
      return false;
    }
  };

  // Delete Post
  const deletePostFetcher = useMemo(
    () =>
      campaignId
        ? (api: ForumApiI, postId: string) =>
            api[SHARED_FORUM_API_METHODS.DELETE_POST](campaignId, postId)
        : null,
    [campaignId]
  );

  const [, deletePost] = useApi({
    apiFactory,
    fetcher: deletePostFetcher,
    shouldCallAutomatically: false,
    showToastOnSuccess: true,
    successBuilder: () => "Post has been deleted successfully!",
    errorBuilder: () =>
      "Something went wrong during post removal. Please try again.",
  });

  const handleDeletePost = async (postId: string) => {
    try {
      await deletePost(postId);

      updatePostById(postId, () => undefined);

      return true;
    } catch (e) {
      return false;
    }
  };

  const commentsNextTokenRef = useRef<string | null>(null);
  const [isReachedEndOfComments, setIsReachedEndOfComments] = useState(false);
  const [internalComments, setInternalComments] = useState<ForumPostCommentI[]>(
    []
  );

  const resetCommentsList = () => {
    setInternalComments([]);
    commentsNextTokenRef.current = null;
    setIsReachedEndOfComments(false);
  };

  // Immutable comments updater utility
  const updateCommentById = useCallback(
    (
      commentId: string,
      updater: (comment: ForumPostCommentI) => ForumPostCommentI | undefined
    ) => {
      const commentToUpdateIndex = _findIndex(internalComments, {
        id: commentId,
      });

      if (commentToUpdateIndex >= 0) {
        setInternalComments((currentInternalComments) =>
          // Reject undefined values (after deletion)
          _filter(
            immutableUpdateArrayItem(
              currentInternalComments,
              `[${commentToUpdateIndex}]`,
              updater
            ),
            Boolean
          )
        );
      }
    },
    [internalComments]
  );

  const getPostDetailsFetcher = useMemo(
    () =>
      campaignId
        ? (api: ForumApiI, postId: string) =>
            api[SHARED_FORUM_API_METHODS.GET_POST_DETAILS](
              campaignId,
              postId,
              commentsNextTokenRef.current
            )
        : null,
    [campaignId]
  );

  const [, loadPost] = useApi({
    apiFactory,
    fetcher: getPostDetailsFetcher,
    shouldCallAutomatically: false,
    shouldResetDataOnRequest: false,
    errorBuilder: () =>
      "Failed to load the requested post. Either it doesn't exist or the provided link is not valid.",
  });

  const handleLoadPost = async (postId: string) => {
    try {
      const getPostResponse = await loadPost(postId);

      return getPostResponse?.data || null;
    } catch (e) {
      return null;
    }
  };

  const [{ isLoading: isLoadingComments }, loadComments] = useApi({
    apiFactory,
    fetcher: getPostDetailsFetcher,
    shouldCallAutomatically: false,
    shouldResetDataOnRequest: false,
    onSuccess: ({ next_token, comments }) => {
      setInternalComments((internalComments) => [
        ...internalComments,
        ...comments,
      ]);

      if (next_token === null || comments.length === 0) {
        setIsReachedEndOfComments(true);
        commentsNextTokenRef.current = null;
      } else {
        commentsNextTokenRef.current = next_token;
      }
    },
    onError: () => {
      setIsReachedEndOfComments(true);
      commentsNextTokenRef.current = null;
    },
    errorBuilder: () =>
      "Failed to load comments. Please try to reopen post details modal.",
  });

  const handleLoadComments = (postId: string) => {
    return loadComments(postId);
  };

  const createCommentFetcher = useMemo(
    () =>
      campaignId
        ? (api: ForumApiI, postId: string, body: string) =>
            api[SHARED_FORUM_API_METHODS.CREATE_COMMENT](campaignId, postId, {
              body,
            })
        : null,
    [campaignId]
  );

  const [, createComment] = useApi({
    apiFactory,
    fetcher: createCommentFetcher,
    shouldCallAutomatically: false,
    onSuccess: resetCommentsList,
    onError: () =>
      "Something went wrong during comment creation. Please ensure that the post you're commenting exists and try again.",
  });

  const handleCreateComment = async (postId: string, body: string) => {
    try {
      await createComment(postId, body);

      void loadComments(postId);

      updatePostById(postId, (post) => ({
        ...post,
        comment_count: post.comment_count + 1,
      }));

      return true;
    } catch (e) {
      return false;
    }
  };

  const editCommentFetcher = useMemo(
    () =>
      campaignId
        ? (api: ForumApiI, postId: string, commentId: string, body: string) =>
            api[SHARED_FORUM_API_METHODS.EDIT_COMMENT](
              campaignId,
              postId,
              commentId,
              {
                body,
              }
            )
        : null,
    [campaignId]
  );

  const [, editComment] = useApi({
    apiFactory,
    fetcher: editCommentFetcher,
    shouldCallAutomatically: false,
    errorBuilder: () =>
      "Something went wrong during comment edit. Please try again.",
  });

  const handleEditComment = async (
    postId: string,
    commentId: string,
    body: string
  ) => {
    try {
      await editComment(postId, commentId, body);

      void loadComments(postId);

      updateCommentById(commentId, (post) => ({
        ...post,
        body,
      }));

      return true;
    } catch (e) {
      return false;
    }
  };

  const upvoteCommentFetcher = useMemo(
    () =>
      campaignId
        ? (api: ForumApiI, postId: string, commentId: string) =>
            api[SHARED_FORUM_API_METHODS.UPVOTE_COMMENT](
              campaignId,
              postId,
              commentId
            )
        : null,
    [campaignId]
  );

  const [, upvoteComment] = useApi({
    apiFactory,
    fetcher: upvoteCommentFetcher,
    shouldCallAutomatically: false,
    errorBuilder: () =>
      "Something went wrong during comment upvote. Please try again.",
  });

  const handleUpvoteComment = async (postId: string, commentId: string) => {
    try {
      updateCommentById(commentId, (comment) => ({
        ...comment,
        upvotes: comment.upvotes + 1,
        upvoted: true,
      }));

      await upvoteComment(postId, commentId);

      return true;
    } catch (e) {
      updateCommentById(commentId, (comment) => ({
        ...comment,
        upvotes: comment.upvotes - 1,
        upvoted: false,
      }));

      return false;
    }
  };

  const deleteCommentFetcher = useMemo(
    () =>
      campaignId
        ? (api: ForumApiI, postId: string, commentId: string) =>
            api[SHARED_FORUM_API_METHODS.DELETE_COMMENT](
              campaignId,
              postId,
              commentId
            )
        : null,
    [campaignId]
  );

  const [, deleteComment] = useApi({
    apiFactory,
    fetcher: deleteCommentFetcher,
    shouldCallAutomatically: false,
    errorBuilder: () =>
      "Something went wrong during comment removal. Please try again.",
  });

  const handleDeleteComment = async (postId: string, commentId: string) => {
    try {
      await deleteComment(postId, commentId);

      updateCommentById(commentId, () => undefined);
      updatePostById(postId, (post) => ({
        ...post,
        comment_count: post.comment_count - 1,
      }));

      return true;
    } catch (e) {
      return false;
    }
  };

  return (
    <ForumContext.Provider
      value={{
        // Posts related state and API calls
        posts: internalPosts,
        createPost: handleCreatePost,
        editPost: handleEditPost,

        loadPosts,
        isLoadingPosts,
        isReachedEndOfPosts,
        configLoadPostsRequest,
        setConfigLoadPostsRequest,

        loadPost: handleLoadPost,

        upvotePost: handleUpvotePost,
        deletePost: handleDeletePost,
        pinPost: handlePinPost,
        unpinPost: handleUnpinPost,
        searchTerm,
        setSearchTerm: handeChangeSearchTerm,
        setSortOrder: handleChangeSortOrder,
        resetPostsList,

        // Comments related state and API calls
        resetCommentsList,
        isLoadingComments,
        loadComments: handleLoadComments,
        createComment: handleCreateComment,
        editComment: handleEditComment,
        isReachedEndOfComments,
        upvoteComment: handleUpvoteComment,
        comments: internalComments,
        deleteComment: handleDeleteComment,
      }}
    >
      {children}
    </ForumContext.Provider>
  );
};
