import { ChangeEventHandler, FC, useState } from "react";

import { CloseIcon } from "shared/ui/icons";
import { ForumPostI } from "shared/lib/interfaces/forum";
import { SalesFloorPostExpanded } from "@/components/modules/sales-floor/post/post-expanded";
import { LoadingComponent } from "@/components/modules/loading";

interface ForumPostExpandedModalPropsI {
  modalId?: string;
  post?: ForumPostI;
  currentUserId?: string;
  isReadOnly?: boolean;
  isInitiallyInEditMode?: boolean;
  onClose?: () => void;
}

export const FORM_POST_EXPANDED_MODAL_ID = "forum-post-expanded-modal";

export const ForumPostExpandedModal: FC<ForumPostExpandedModalPropsI> = ({
  modalId = FORM_POST_EXPANDED_MODAL_ID,
  post,
  currentUserId,
  isReadOnly = false,
  isInitiallyInEditMode = false,
  onClose,
}) => {
  const [isContentVisible, setIsContentVisible] = useState(false);

  const handleCheck: ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.target.checked;

    if (value) {
      setIsContentVisible(true);
    } else {
      if (onClose) {
        onClose();
      }

      setTimeout(() => {
        setIsContentVisible(false);
      }, 300);
    }
  };

  return (
    <>
      <input
        type="checkbox"
        id={modalId}
        className="modal-toggle"
        onChange={handleCheck}
      />

      <label htmlFor={modalId} className="modal cursor-pointer">
        <label htmlFor="" className="w-full min-w-[720px] max-w-[720px]">
          <div className="modal-box relative w-full min-w-full rounded-lg p-0">
            <div className="flex items-center justify-end">
              <label
                htmlFor={modalId}
                className="btn btn-circle btn-ghost btn-sm absolute right-6 top-6 flex cursor-pointer items-center justify-center"
              >
                <CloseIcon className="relative h-4 w-4 text-black/40" />
              </label>

              <div className="w-full">
                {isContentVisible && post ? (
                  <SalesFloorPostExpanded
                    post={post}
                    currentUserId={currentUserId}
                    isInitiallyInEditMode={isInitiallyInEditMode}
                    isReadOnly={isReadOnly}
                  />
                ) : (
                  <LoadingComponent />
                )}
              </div>
            </div>
          </div>
        </label>
      </label>
    </>
  );
};
