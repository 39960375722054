import SalesFloorPostEditor from "shared/ui/sales-floor/post-editor";
import { ForumPostI } from "shared/lib/interfaces/forum";
import { MouseEventHandler } from "react";

// This component is used to allow editing post title and body from within the expanded view
export const SalesFloorExpandedPostEditor = ({
  post,
  onSubmit,
  onExitEditMode,
}: {
  post: ForumPostI;
  onSubmit: (title: string, body: string) => Promise<boolean>;
  onExitEditMode?: () => void;
}) => {
  const handleCancelButtonClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    onExitEditMode?.();
  };

  return (
    <SalesFloorPostEditor
      isAuthorVisible={false}
      className="mt-4 rounded-[12px] border border-[#EEEEEE] pt-0"
      editorContentClassName="mt-3 mb-2"
      title={post.title}
      body={post.body}
      onSubmit={onSubmit}
      onCancel={handleCancelButtonClick}
    />
  );
};
