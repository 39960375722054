import { FC, MouseEventHandler, useEffect } from "react";
import { EditorContent, useEditor } from "@tiptap/react";
import Link from "@tiptap/extension-link";
import clsx from "clsx";

import { COMMON_TIPTAP_EXTENSIONS } from "../../constants/tiptap";
import CollapsibleText from "shared/ui/collapsible-text";

interface SalesFloorPostContentPropsI {
  title: string;
  body: string;
  isPreview?: boolean;
  isComment?: boolean;
}

const extensions = [
  ...COMMON_TIPTAP_EXTENSIONS,
  Link.configure({ openOnClick: true }),
];

export const SalesFloorPostContent: FC<SalesFloorPostContentPropsI> = ({
  title,
  body,
  isPreview = false,
  isComment = false,
}) => {
  const editor = useEditor({
    editable: false,
    extensions,
    content: body,
  });

  useEffect(() => {
    if (editor) {
      editor.commands.setContent(body);
    }
  }, [body, editor]);

  const editorHandleClickCapture: MouseEventHandler<HTMLDivElement> = (e) => {
    if (isPreview) {
      e.preventDefault();
    }
  };

  const editorContent = (
    <EditorContent
      onClickCapture={editorHandleClickCapture}
      editor={editor}
      className={clsx({
        "ae-typography-body1 font-normal": isComment || isPreview,
      })}
    />
  );

  return (
    <section className="flex w-full flex-col gap-3">
      {title && <p className="ae-typography-h2">{title}</p>}

      {isComment || isPreview ? (
        <CollapsibleText
          visibleLines={2}
          buttonClassName={clsx("b-typography-button2", {
            "text-[#0F35FF]": !isComment,
          })}
          disableButton={isPreview}
        >
          {editorContent}
        </CollapsibleText>
      ) : (
        editorContent
      )}
    </section>
  );
};
