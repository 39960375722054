import { FC } from "react";
import { ChatBubbleLeftRightIcon } from "@heroicons/react/24/outline";

interface SalesFloorPostCommentsLabelPropsI {
  commentsCount: number;
}

export const SalesFloorPostCommentsLabel: FC<
  SalesFloorPostCommentsLabelPropsI
> = ({ commentsCount }) => (
  <div className="flex gap-3 text-[#999] transition-colors hover:text-black">
    <ChatBubbleLeftRightIcon className="w-4" />
    <span className="ae-typography-body1">{commentsCount}</span>
  </div>
);
