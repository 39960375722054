import { useRef, FC, MutableRefObject, useMemo } from "react";
import { Field, Formik, Form, FormikProps } from "formik";
import * as Yup from "yup";
import clsx from "clsx";

import { TextField } from "shared/ui/user-input";
import { CloseIcon } from "shared/ui/icons";
import { HTTPS_REGEX } from "shared/lib/constants/urls";

const EnhancedTextField = TextField(Field);

interface LinkManagerModalPropsI {
  modalId?: string;
  link?: string;
  onSubmit: (linkValue: string) => void;
  onClose: () => void;
}

interface LinkManagerFormI {
  link: string;
}

export const POST_LINK_MANAGER_MODAL_ID = "post-link-manager-modal";

const LINK_MAX_LENGTH = 120;

const FORM_SCHEMA = Yup.object().shape({
  link: Yup.string()
    .max(
      LINK_MAX_LENGTH,
      `Link must be less than or equal to ${LINK_MAX_LENGTH} characters.`
    )
    .matches(HTTPS_REGEX, "Provide a valid link in the https://... format.")
    .required("Link is required."),
});

const LinkManagerModal: FC<LinkManagerModalPropsI> = ({
  modalId = POST_LINK_MANAGER_MODAL_ID,
  link,
  onSubmit = () => {},
  onClose = () => {},
}) => {
  const formRef = useRef() as MutableRefObject<FormikProps<LinkManagerFormI>>;
  const checkboxRef = useRef() as MutableRefObject<HTMLInputElement>;

  const initialValues = useMemo(
    () => ({
      link: link || "",
    }),
    [link]
  );

  const handleClose = () => {
    onClose();
    formRef.current?.resetForm();

    if (checkboxRef.current) {
      checkboxRef.current.checked = false;
    }
  };

  const handleSubmit = ({ link }: LinkManagerFormI) => {
    onSubmit(link);
    handleClose();
  };

  return (
    <>
      <input
        type="checkbox"
        className="modal-toggle"
        id={modalId}
        ref={checkboxRef}
      />

      <div className="modal">
        <div className="modal-box relative min-w-[520px] p-6">
          <div className="flex items-center justify-between">
            <h3 className="ae-typography-h3">{link ? "Edit" : "Add"} Link</h3>

            <button
              className="btn btn-circle btn-ghost btn-sm"
              onClick={handleClose}
            >
              <CloseIcon className="h-4 w-4 cursor-pointer" />
            </button>
          </div>

          <p className="ae-typography-body2 text-base-content/60">
            {link
              ? "Update the URL of selected link"
              : "Enter the URL you’d like to use starting with https://"}
          </p>

          <Formik
            enableReinitialize
            innerRef={formRef}
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={FORM_SCHEMA}
          >
            {({ errors, touched, isValid }) => (
              <Form className="flex flex-col">
                <EnhancedTextField
                  name="link"
                  errors={errors.link}
                  touched={touched.link}
                  placeholder="Add link"
                  className="mt-5"
                  labelClassName="hidden"
                  inputClassName="b-typography-body3"
                  inputProps={{
                    maxLength: LINK_MAX_LENGTH,
                    autoComplete: "off",
                  }}
                />

                <div className="flex justify-end gap-3">
                  <button
                    type="button"
                    className="btn btn-ae-text"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>

                  <button
                    type="submit"
                    className={clsx("btn btn-ae-default w-[140px]", {
                      disabled: !isValid,
                    })}
                  >
                    {link ? "Update link" : "Link"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default LinkManagerModal;
